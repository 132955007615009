<template>
  <div>
    <v-card-text v-if="layer.class_name == 'ComptageLayer'" class="justify-center">
      <v-autocomplete
        prepend-icon="chat_bubble_outline"
        :items="clusterPropertiesItems"
        :value="local_options.keys[0]"
        @input="local_options.keys = [$event]"
        :label="$t('props_mapping.tooltip.aggregated_property')"
        :no-data-text="$t('props_mapping.tooltip.select_no_data')"
        @change="update"
        :placeholder="$t('props_mapping.tooltip.select_placeholder')"
        persistent-placeholder
      >
      </v-autocomplete>
    </v-card-text>
    <v-card-text v-else class="justify-center">
      <v-autocomplete
        prepend-icon="chat_bubble_outline"
        :items="dataPropertiesItems"
        v-model="local_options.keys"
        :label="$t('props_mapping.tooltip.select_label')"
        :no-data-text="$t('props_mapping.tooltip.select_no_data')"
        multiple
        @change="update"
        :placeholder="$t('props_mapping.tooltip.select_placeholder')"
        persistent-placeholder
      >
      </v-autocomplete>
    </v-card-text>
  </div>
</template>

<script>
import Vue from "vue";
import LayerMappingMixin from "./layer_mapping_mixin.vue";

export default Vue.component("tooltip-mapping", {
  mixins: [LayerMappingMixin],

  data() {
    return {};
  },
  computed: {
    clusterPropertiesItems() {
      let res = [
        { value: "NONE", text: this.$t("props_mapping.tooltip.none") },
        { value: "point_count_abbreviated", text: this.$t("props_mapping.tooltip.nb_points") },
        ...this.dataPropertiesItems
      ];
      return res;
    }
  },
  methods: {}
});
</script>
