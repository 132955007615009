import { render, staticRenderFns } from "./layer_legend.vue?vue&type=template&id=0e5a5486&scoped=true"
import script from "./layer_legend.vue?vue&type=script&lang=ts"
export * from "./layer_legend.vue?vue&type=script&lang=ts"
import style0 from "./layer_legend.vue?vue&type=style&index=0&id=0e5a5486&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.aspect_rules_js/vue-loader@15.11.1_-22685427/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e5a5486",
  null
  
)

export default component.exports