<!--
  Layer legend
-->

<template>
  <div class="pa-0">
    <span v-html="legend_options.text"></span>
    <div v-if="layer.main_dataset">
      {{ "source: " + datasetToStr(datasets[layer.main_dataset], true) }}
    </div>
    <div v-if="legend_options.unit">
      {{ "(" + legend_options.unit + ")" }}
    </div>
    <div v-for="(item, index) in legend_values" :key="index" class="legend">
      <i :style="'background: ' + item.value + '; opacity: 1'"></i>
      {{ item.label }}
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";
import { datasetToStr } from "@/functions-tools";

export default Vue.component("layer-legend", {
  components: {},

  props: ["layer"],

  data: function () {
    return {};
  },

  computed: {
    ...mapState(["datasets"]),
    ...mapState("layers", ["allLayers"]),
    legend() {
      return this.layer.legend;
    },
    legend_values() {
      return this.legend.getLegendMap();
    },
    legend_options() {
      return this.legend.legend_options;
    }
  },
  methods: {
    datasetToStr
  }
});
</script>

<style scoped>
.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.75;
}

/* text size for all texts */
* {
  font-size: 0.95em;
}
</style>
