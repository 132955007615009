<!--
Card describing the source of a data in Kite
-->

<template>
  <v-card v-if="datasetItem" color="primary" dark shaped>
    <v-card-title>
      {{ displayedName }}
      <v-spacer />
      <div v-if="datasetItem.url">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon :href="datasetItem.url" target="_blank" v-on="on">
              <v-icon>launch</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("basic_dialogs.open_in_new_tab") }}</span>
        </v-tooltip>
      </div>
    </v-card-title>
    <v-card-subtitle>
      {{ datasetItem.provider_name }}
    </v-card-subtitle>
    <v-card-text>
      {{ datasetItem.description }}
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { datasetToStr } from "@/functions-tools";

export default Vue.component("kite-data-source", {
  props: {
    // id of the data source
    source: {
      type: String
    }
  },
  data: () => ({}),

  computed: {
    ...mapState(["datasets", "language"]),
    datasetItem() {
      if (!this.source) {
        return undefined;
      }

      return this.datasets[this.source];
    },
    displayedName() {
      return datasetToStr(this.datasetItem);
    }
  },
  methods: {}
});
</script>
