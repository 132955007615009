<!--
Display gtfs route cover 
-->

<template>
  <pt-line-stats
    :line-geojson="routeStopsGeojson"
    :line-geojson-data="fetchData"
    :content-keys="['poptot', 'comp', 'stat', 'sch']"
    dense
    compute-button
    :key="key"
  />
</template>

<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";
import NetworkInfoMixin from "./network_info_mixin.vue";
import PtLineStats from "@/components/transports/pt_stats.vue";

export default Vue.component("route-cover", {
  components: { PtLineStats },
  mixins: [NetworkInfoMixin],

  data() {
    return {
      key: 0
    };
  },
  watch: {
    content() {
      this.key += 1;
    }
  },

  computed: {
    ...mapState("network", ["ptLineStats"]),
    routeStopsGeojson() {
      let relevant_stops = this.currentNetworkView.stops.filter(stop => {
        return stop.passing_routes.includes(this.content.route_id);
      });
      return {
        type: "FeatureCollection",
        features: relevant_stops.map(stop => {
          return {
            type: "Feature",
            geometry: stop.geometry,
            properties: {}
          };
        })
      };
    }
  },

  methods: {
    async fetchData(lineGeojson, bufferSize, mount) {
      if (this.content.route_id == this.ptLineStats.route_id && bufferSize == this.ptLineStats.buffer_size) {
        return this.ptLineStats.data;
      } else {
        let data;
        if (lineGeojson.features.length == 0) {
          data = {};
        } else {
          if (mount) {
            return {};
          } else {
            data = await this.$whale.transportLinePopulation(lineGeojson, bufferSize, 0);
          }
        }
        this.$store.commit("network/SET_PT_LINE_STATS", {
          route_id: this.content.route_id,
          buffer_size: bufferSize,
          data
        });
        return data;
      }
    }
  }
});
</script>
