/* layers.store.js 

Store containing the Mapbox layers displayed in Kite.
*/

import { getInstance } from "@/api/index";
import i18n from "@/plugins/lang";
import { CustomLayerView, DatabaseLayerView, BuiltinLayerView } from "@/models";
import { sortFromList } from "@/functions-tools";
import { change_base_layer } from "@/kite_layers";

function getIndexInZList(state, layerId) {
  let index = state.zList.indexOf(layerId);
  if (index == -1) {
    let layer = state.allLayers[layerId];
    if (layer !== undefined && layer.parentId !== undefined) {
      index = getIndexInZList(state, layer.parentId);
    }
  }
  return index;
}

function transformLayerObject(item) {
  return { layer: item, themes: item.themes, available: isDbLayerAvailable(item), added: false };
}

function isDbLayerAvailable(layer_item) {
  let whale = getInstance();
  if (layer_item.data.startsWith("https://")) {
    return true;
  } else {
    return whale.hasAccess("LAYERS");
  }
}

function sortLayersLikeZList(layer_list, zList) {
  return layer_list.sort((a, b) => {
    let index_a = zList.indexOf(a);
    let index_b = zList.indexOf(b);
    if (index_a == -1) {
      throw new Error("Layer id " + a + " is absent from zList");
    }
    if (index_b == -1) {
      throw new Error("Layer id " + b + " is absent from zList");
    }

    let res = index_a > index_b ? 1 : -1;
    if (index_a == index_b) {
      res = 0;
    }
    return res;
  });
}

const initState = () => {
  return {
    map: null,
    draw: null,
    allLayers: {},
    allViews: {},
    zList: [],
    addDialog: false,
    addTab: 0,
    spatialFilter: true,
    mapMoved: true,
    nbCustomLayers: 0,
    visibleLayers: [],
    nonEmptyLayers: [],
    baseLayer: "osm-classic",
    baseLayerOpacity: 0.7,
    database_layers: [],
    full_database_layers: [],
    unifiedPopup: null,
    layersPopup: [],
    printMapTitle: "Titre de la carte",
    legendsKey: 0
  };
};
const state = initState();

export default {
  namespaced: true,
  // State object
  state,

  // Getter functions
  getters: {
    getLayerView: state => layer_id => {
      return state.allViews[layer_id];
    },
    getLayer: state => layer_id => {
      return state.allLayers[layer_id];
    },
    displayedLayers: state => {
      let non_empty_layers = state.nonEmptyLayers.filter(id => {
        return state.allLayers[id].display_mode != "hidden";
      });
      return sortFromList(non_empty_layers, state.zList).reverse();
    },
    availableDatabaseLayers: state => {
      let db_layers;
      if (state.spatialFilter) {
        db_layers = state.database_layers;
      } else {
        db_layers = state.full_database_layers;
      }

      let zList = state.zList;
      return db_layers.reverse();
    },
    databaseLayerThemes: state => {
      let db_layers = state.full_database_layers;
      return [...new Set(db_layers.map(item => item.themes).flat())];
    },
    orderedLayersPopup: state => {
      return sortLayersLikeZList(state.layersPopup, state.zList);
    }
  },

  // Actions
  actions: {
    updateAddDialog(context, value) {
      context.commit("UPDATE_ADD_DIALOG", value);
      context.dispatch("checkDatabaseLayersUpdate");
    },
    updateAddTab(context, value) {
      context.commit("UPDATE_ADD_TAB", value);
      context.dispatch("checkDatabaseLayersUpdate");
    },
    setSpatialFilter(context, value) {
      context.commit("SET_SPATIAL_FILTER", value);
      context.dispatch("checkDatabaseLayersUpdate");
    },
    checkDatabaseLayersUpdate(context) {
      let state = context.state;
      // update list of database layers if
      // addDialog is open on database tab, with spatial filter on, and map has been movd since last update
      if (state.addDialog && state.addTab == 0 && state.spatialFilter && state.mapMoved) {
        context.dispatch("getDatabaseLayers");
        context.commit("SET_MAP_MOVED", false);
      }
    },
    changeBaseLayer(context, baseLayer) {
      context.commit("CHANGE_BASE_LAYER", baseLayer);
    },
    // fetch and set the database layers
    async getDatabaseLayers(context, map_bound: Boolean = true) {
      let whale = getInstance();
      // use map bounds if asked
      let bounds = undefined;
      if (map_bound) {
        bounds = context.state.map.getBounds();
      }

      // fetch and enrich list of layer items
      try {
        let optional_layers = (await whale.getLayersTable(bounds)).map(item => transformLayerObject(item));

        // set database layers list
        context.commit("SET_DATABASE_LAYERS", optional_layers);
      } catch (e) {
        console.log(e);
        let message = i18n.t("map_layers.database_layers.refresh_error");
        alert({ message, type: "error" });
      }
    },
    async getFullDatabaseLayersTable(context) {
      if (context.state.full_database_layers.length == 0) {
        let whale = getInstance();
        try {
          let full_database_table = (await whale.getLayersTable()).map(item => transformLayerObject(item));

          // set database layers list
          context.commit("SET_FULL_DATABASE_LAYERS", full_database_table);
        } catch (e) {
          console.log(e);
          let message = i18n.t("map_layers.layer_table.errors.loading");
          alert({ message, type: "error" });
        }
      }
    },

    // add the layer object to the collection of layers
    // this is done at the layer creation
    addLayerToCollection(context, layer) {
      context.commit("ADD_LAYER_TO_COLLECTION", layer);
    },

    // add the layer to Kite's map and update the z-list
    // this is done after creating the layer, when the map is set
    addLayerToKite(context, layer) {
      // add the layer to the map
      context.commit("ADD_LAYER_TO_MAP", layer);

      // insert the layer in the z-list
      context.commit("INSERT_LAYER_IN_Z_LIST", layer);

      // update lists containing visible layers and non empty layers
      context.commit("UPDATE_VISIBLE_LAYERS_LIST", { layer_id: layer.id });
      context.commit("UPDATE_EMPTY_LAYERS_LIST", { layer_id: layer.id });
    },

    // move the given layer in the z-list
    moveLayer(context, { layer, newIndex }) {
      let layerIndex = getIndexInZList(context.state, layer.id);

      if (layerIndex == -1) {
        // throw an error if the layer is not in z-list
        throw new Error("Cannot move unknown layer.");
      }

      // remove the layer from the zList
      context.commit("REMOVE_LAYER_FROM_Z_LIST", layer);

      // evaluate the new position of the layer
      let displayed_layers = context.getters.displayedLayers;
      let newIndexZlist = displayed_layers.length - newIndex - 1;

      // evaluate future next layer, if there is one
      let beforeId = null;
      if (newIndex == 0) {
        beforeId = undefined;
      } else {
        let layerBefore = displayed_layers[newIndexZlist + 1];
        layerBefore = context.state.allLayers[layerBefore];
        beforeId = layerBefore.getLastLayerId();
      }
      layer.setBeforeId(beforeId);

      // insert the layer back in the zList, at its new place
      context.commit("INSERT_LAYER_IN_Z_LIST", layer);

      // update the map with the new layer order
      layer.moveLayer();
    },

    // move the given layer up in the z-list
    moveLayerUp(context, layer) {
      let layerIndex = getIndexInZList(context.state, layer.id);

      if (layerIndex == -1) {
        // throw an error if the layer is not in z-lisy
        throw new Error("Cannot move unknown layer.");
      } else if (layerIndex == context.state.zList.length - 1) {
        // ignore move if already on top
        return;
      }

      let layerAfter = context.state.allLayers[context.state.zList[layerIndex + 1]];

      context.commit("REMOVE_LAYER_FROM_Z_LIST", layer);

      layer.setBeforeId(layerAfter.beforeId);

      context.commit("INSERT_LAYER_IN_Z_LIST", layer);

      layer.moveLayer();
    },

    // move the given layer down in the z-list
    moveLayerDown(context, layer) {
      let layerIndex = getIndexInZList(context.state, layer.id);

      if (layerIndex == -1) {
        // throw an error if the layer is not in z-lisy
        throw new Error("Cannot move unknown layer.");
      } else if (layerIndex == 0) {
        // ignore move if already at bottom
        return;
      }

      let layerBefore = context.state.allLayers[context.state.zList[layerIndex - 1]];

      context.commit("REMOVE_LAYER_FROM_Z_LIST", layer);

      layer.setBeforeId(layerBefore.getLastLayerId());

      context.commit("INSERT_LAYER_IN_Z_LIST", layer);

      layer.moveLayer();
    },

    setLayersData(context, payload) {
      let layerIds = payload.ids;
      if (typeof layerIds == "string") {
        layerIds = [layerIds];
      }
      layerIds.forEach(layer_id => {
        context.commit("SET_LAYER_DATA", { layer_id: layer_id, data: payload.data });
        context.commit("UPDATE_EMPTY_LAYERS_LIST", { layer_id: layer_id });
      });
    },

    setLayersVisibility(context, payload) {
      let layerIds = payload.ids;
      if (typeof layerIds == "string") {
        layerIds = [layerIds];
      }
      layerIds.forEach(layer_id => {
        let pl = { layer_id, isVisible: payload.isVisible };
        context.commit("SET_LAYER_VISIBILITY", pl);
        context.commit("UPDATE_VISIBLE_LAYERS_LIST", { layer_id });
      });
    },

    setLayersLoading(context, payload) {
      let layerIds = payload.ids;
      if ((layerIds = "all")) {
        layerIds = context.state.zList;
      } else if (typeof layerIds == "string") {
        layerIds = [layerIds];
      }
      layerIds.forEach(layer_id => {
        let layer = context.getters.getLayer(layer_id);
        if (layer && layer.source) {
          layer.source.setLoading(payload.value);
        }
      });
    },

    deleteLayer(context, layer) {
      context.commit("UPDATE_EMPTY_LAYERS_LIST", { layer_id: layer.id, isEmpty: true });
      context.commit("UPDATE_VISIBLE_LAYERS_LIST", { layer_id: layer.id, isVisible: false });
      context.commit("REMOVE_LAYER_FROM_Z_LIST", layer);
      context.commit("REMOVE_LAYER_FROM_COLLECTION", layer);
      context.commit("REMOVE_LAYER_FROM_POPUP", layer.id);
      layer.removeListeners();
      layer.removeLayer();
      layer.removeSource();
    },

    hideUserLayers(context) {
      let allLayers = context.state.allLayers;
      let userLayers = context.state.zList.filter(layer_id => {
        return allLayers[layer_id].isUserLayer();
      });
      context.dispatch("setLayersVisibility", { ids: userLayers, isVisible: false });
    }
  },

  // Mutations
  mutations: {
    UPDATE_MAP_PRINT_TITLE(state, title) {
      state.printMapTitle = title;
    },
    CHANGE_BASE_LAYER(state, baseLayer) {
      // change background layer
      change_base_layer(state.map, baseLayer);
      state.baseLayer = baseLayer;

      // update opacity of new layer
      state.map.setPaintProperty(state.baseLayer, "raster-opacity", state.baseLayerOpacity);
    },
    CHANGE_BASE_LAYER_OPACITY(state, opacity) {
      state.map.setPaintProperty(state.baseLayer, "raster-opacity", opacity);
      state.baseLayerOpacity = opacity;
    },

    SET_MAP_DRAW(state, mapbox_draw) {
      state.draw = mapbox_draw;
    },

    SET_LAYER_DATA(state, { layer_id, data }) {
      let layer = state.allLayers[layer_id];
      if (layer) {
        layer.setData(data);
      }
    },

    UPDATE_EMPTY_LAYERS_LIST(state, { layer_id, isEmpty }) {
      if (isEmpty === undefined) {
        let layer = state.allLayers[layer_id];
        if (layer) {
          isEmpty = layer.isDataEmpty();
        }
      }

      if (!isEmpty) {
        if (!state.nonEmptyLayers.includes(layer_id)) {
          state.nonEmptyLayers.push(layer_id);
        }
      } else {
        state.nonEmptyLayers = state.nonEmptyLayers.filter(id => id != layer_id);
      }
    },

    SET_LAYER_VISIBILITY(state, { layer_id, isVisible, onlyUpdateList }) {
      let layer = state.allLayers[layer_id];
      if (layer) {
        layer.updateVisibility(isVisible);
      }
    },

    UPDATE_VISIBLE_LAYERS_LIST(state, { layer_id, isVisible }) {
      if (isVisible === undefined) {
        let layer = state.allLayers[layer_id];
        if (layer) {
          isVisible = layer.isVisible;
        }
      }

      if (isVisible) {
        if (!state.visibleLayers.includes(layer_id)) {
          state.visibleLayers.push(layer_id);
        }
      } else {
        state.visibleLayers = state.visibleLayers.filter(id => id != layer_id);
      }
    },

    ADD_LAYER_TO_COLLECTION(state, layer) {
      if (layer.id in state.allLayers) {
        throw new Error("Layer '" + layer.id + "' already exists !");
      } else {
        state.allLayers[layer.id] = layer;
        let layer_view;
        if (layer.isUserLayer()) {
          if (layer.isCustomLayer()) {
            layer_view = new CustomLayerView(layer);
          } else {
            layer_view = new DatabaseLayerView(layer);
          }
        } else {
          layer_view = new BuiltinLayerView(layer);
        }
        state.allViews[layer.id] = layer_view;
      }
    },

    REMOVE_LAYER_FROM_COLLECTION(state, layer) {
      if (layer.id in state.allLayers) {
        delete state.allLayers[layer.id];
      }
      if (layer.id in state.allViews) {
        delete state.allViews[layer.id];
      }
    },

    // add the layer to the Kite's map
    ADD_LAYER_TO_MAP(state, layer) {
      if (state.map == null) {
        throw new Error("Cannot add layer, map is not set !");
      } else if (layer.beforeId != undefined && !state.zList.includes(layer.beforeId)) {
        throw new Error("Cannot add before layer '" + layer.beforeId + "', this layer does not exist.");
      }
      layer.addToMap(state.map);
    },

    INSERT_LAYER_IN_Z_LIST(state, layer) {
      if (!(layer.id in state.allLayers)) {
        throw new Error("Trying to insert layer that was not added to the collection");
      }

      // get the insertion index
      let beforeId = layer.beforeId;
      let beforeIndex = null;
      if (beforeId == undefined) {
        beforeIndex = state.zList.length;
      } else {
        beforeIndex = getIndexInZList(state, beforeId);
      }

      // throw an error if beforeId is not in the z-list
      if (beforeIndex == -1) {
        throw new Error("Cannot insert before layer '" + beforeId + "', this layer does not exist.");
      }

      // insert the layer in the z-list
      state.zList.splice(beforeIndex, 0, layer.id);

      // if inserted on top of another layer, update the beforeId of the layer below
      if (beforeIndex > 0) {
        let layerBefore = state.allLayers[state.zList[beforeIndex - 1]];
        layerBefore.setBeforeId(layer.getLastLayerId());
      }
    },

    REMOVE_LAYER_FROM_Z_LIST(state, layer) {
      let layerIndex = getIndexInZList(state, layer.id);

      // throw an error if the layer is not in the z-list
      if (layerIndex == -1) {
        throw new Error("Cannot remove layer '" + layer.id + "', this layer does not exist.");
      }

      // remove the layer from the z-list
      state.zList.splice(layerIndex, 1);

      // if removed layer is not the first one, update the beforeId of the layer below
      if (layerIndex > 0) {
        let layerBefore = state.allLayers[state.zList[layerIndex - 1]];
        let nextLayer = undefined;
        if (layerIndex < state.zList.length) {
          nextLayer = layer.beforeId;
        }
        layerBefore.setBeforeId(nextLayer);
      }
    },
    SET_MAP_MOVED(state, value) {
      state.mapMoved = value;
    },
    SET_SPATIAL_FILTER(state, value) {
      state.spatialFilter = value;
    },
    UPDATE_ADD_TAB(state, value) {
      state.addTab = value;
    },
    UPDATE_ADD_DIALOG(state, value) {
      state.addDialog = value;
    },
    SET_MAP(state, map) {
      state.map = map;
    },

    RESET_STORE(state) {
      const newState = initState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_DATABASE_LAYERS(state, db_layers) {
      state.database_layers = db_layers;
    },
    SET_FULL_DATABASE_LAYERS(state, db_layers) {
      state.full_database_layers = db_layers;
    },
    INCREMENT_NB_CUSTOM_LAYERS(state) {
      state.nbCustomLayers += 1;
    },
    SET_UNIFIED_POPUP(state, popup) {
      state.unifiedPopup = popup;
    },
    ADD_LAYER_TO_POPUP(state, layer_id) {
      if (!state.layersPopup.includes(layer_id)) {
        state.layersPopup.push(layer_id);
      }
    },
    REMOVE_LAYER_FROM_POPUP(state, layer_id) {
      let index = state.layersPopup.indexOf(layer_id);
      if (index != -1) {
        state.layersPopup.splice(index, 1);
      }
    },
    INCREMENT_LEGENDS_KEY(state) {
      state.legendsKey = state.legendsKey + 1;
    }
  }
};
