import { zoomOnGeojson, sec2hourformat } from "@/functions-tools";
import * as turf from "@turf/turf";
import store from "@/store";

// maximum number of mobile agents displayed
const MAX_MOBILE_AGENTS = 1000;

// icons directory
const ICONS_PATH = "static/icons/";

const ICONS = {
  "t-user": ["user-dark.svg", "#4c85cc", [20, 43]],
  "t-truck": ["truck.svg", "#5b3c11", [29, 27]],
  "t-odt": ["odt.svg", "#bf2877", [28, 28]],
  "t-odt-blue": ["odt-blue.svg", "#3d6482", [28, 28]],
  "t-odt-green": ["odt-green.svg", "#85c287", [28, 28]],
  "t-odt-green-light": ["odt-green-light.svg", "#cce5cc", [28, 28]],
  "t-odt-green-dark": ["odt-green-dark.svg", "#4d944d", [28, 28]],
  "t-odt-green-dark2": ["odt-green-dark2.svg", "#2f592f", [28, 28]],
  "t-odt-grey": ["odt-grey.svg", "#c2c2c2", [28, 28]],
  "t-odt-orange": ["odt-orange.svg", "#ffa800", [28, 28]],
  "t-odt-red": ["odt-red.svg", "#ff0000", [28, 28]],
  "t-bike": ["bike.svg", "#85c287", [30, 18]],
  "t-station": ["station.svg", "#992ea4", [10, 20]],
  "t-station-circle": ["station-circle.svg", "#000000", [25, 25]],
  "t-station-circle-black": ["station-circle-black.svg", "#000000", [25, 25]],
  "t-ufo": ["ufo.svg", "#bfbfbf", [30, 20]],
  "t-car": ["car.svg", "#ff0000", [25, 21]],
  "t-car-grey": ["car-grey.svg", "#c2c2c2", [25, 21]],
  "t-car-green": ["car-green.svg", "#85c287", [25, 21]],
  "t-car-green-light": ["car-green-light.svg", "#cce5cc", [25, 21]],
  "t-car-green-dark": ["car-green-dark.svg", "#4d944d", [25, 21]],
  "t-car-green-dark2": ["car-green-dark2.svg", "#2f592f", [25, 21]],
  "t-car-blue": ["car-blue.svg", "#3d6482", [25, 21]],
  "t-kick-scooter": ["kick-scooter-dark.svg", "#85c287", [24, 22]],
  "t-scooter": ["scooter.svg", "#85c287", [29, 17]],
  "t-bus": ["bus-dark.svg", "#bfcb51", [29, 30]],
  "t-tram": ["tram-dark.svg", "#42b126", [24, 36]],
  "t-subway": ["subway.svg", "#0ca86c", [28, 34]],
  "t-train": ["train.svg", "#335a28", [27, 34]],
  "t-stop_point": ["stop_point.svg", "#85c287", [10, 20]],
  "t-school-backpack": ["school-backpack.svg", "#3d6482", [23, 30]],
  "t-school-backback-green": ["school-backpack-green.svg", "#85c287", [22, 29]],
  "t-santa": ["santa.svg", "#000000", [200, 200]],
  "t-gift": ["gift.svg", "#000000", [30, 30]],
  "t-elf": ["elf.svg", "#000000", [70, 200]]
};

function read_trace(geojson, use_static_layer, stopped_point_refresh, show_users = true) {
  let colored_icons = [];
  let mobile_attributes = ["--", "agent_id"];

  let positions = [];
  let durations = [];

  // read trace version
  let version = 0;
  if (geojson.version) {
    version = parseInt(geojson.version.toString().split(".")[0]);
  }
  if (version != 1) {
    throw new Error("Unsupported trace version");
  }

  // global preprocessing
  geojson.features.forEach(feature => {
    // hide users if asked
    if (!show_users && feature.properties.icon_type == "user") {
      feature.properties.icon_type = "none";
    }
    // add icon prefix
    feature.properties.icon_type = `t-${feature.properties.icon_type}`;

    // icon color
    if ("icon_color" in feature.properties) {
      colored_icons.push([feature.properties.icon_type, feature.icon_color]);
      feature.icon_type = colored_icon_id(feature.icon_type, feature.icon_color);
    }

    // add mobile attributes
    let feature_attributes = Object.keys(feature.properties.information || {});
    for (let attribute of feature_attributes) {
      if (!mobile_attributes.includes(attribute)) {
        mobile_attributes.push(attribute);
      }
    }

    // add show_attribute prop
    feature.properties.show_attribute = "";
  });

  let static_features = geojson.features.filter(feature => feature.geometry.type == "Point");

  static_features.forEach(feature => {
    // create empty position information
    addEmptyPositionInformation(feature);

    // fill position information (stays at same place)
    let coordinates = feature.geometry.coordinates;
    feature.properties.information._position = {
      values: [coordinates, coordinates],
      timestamps: [0, 0]
    };

    // add first-last data
    Object.keys(feature.properties.information).forEach(key => {
      feature.properties.information[key] = computeFirstLastProperties(feature.properties.information[key]);
    });
  });

  let mobile_features = geojson.features.filter(feature => feature.geometry.type == "LineString");

  mobile_features.forEach((feature, index) => {
    // store id (clean ?)
    feature.num = index;

    // save icon_type
    feature.properties.icon_type_save = feature.properties.icon_type;

    // create empty position information
    addEmptyPositionInformation(feature);

    // fill position information
    feature.properties.information._position = {
      values: feature.geometry.coordinates,
      timestamps: feature.properties.timestamps
    };
    delete feature.properties.timestamps;

    // add first-last data
    Object.keys(feature.properties.information).forEach(key => {
      feature.properties.information[key] = computeFirstLastProperties(feature.properties.information[key]);
    });

    // convert to Point (first LineString coordinates)
    feature.geometry.type = "Point";
    feature.geometry.coordinates = feature.geometry.coordinates[0];

    // add appear-disappear info
    addAppearDisappear(feature, stopped_point_refresh);

    // store positions
    positions.push(feature.properties.information._position.values);
    durations.push(feature.properties.information._position.timestamps);

    delete feature.properties.information._position;
  });

  let area_features = geojson.features.filter(feature => feature.geometry.type == "MultiPolygon");

  let mobile_agent_types = Array.from(new Set(mobile_features.map(d => d.properties.agent_type))).sort();

  return {
    colored_icons,
    mobile_features,
    static_features,
    area_features,
    positions,
    durations,
    mobile_attributes,
    mobile_agent_types
  };
}

function addEmptyPositionInformation(feature) {
  if (!Object.keys(feature.properties).includes("information")) {
    feature.properties.information = {
      _position: {}
    };
  } else {
    feature.properties.information._position = {};
  }
}

function addAppearDisappear(feature, refresh_rate) {
  let timestamps = feature.properties.information._position.timestamps;
  let time_appear = Math.min(...timestamps.filter(d => d > 0));
  let time_disappear = Math.max(...timestamps.filter(d => d < 99999));
  // not rounded time appear
  feature.properties["time_appear_real"] = time_appear;
  feature.properties["time_disappear_real"] = time_disappear;
  // TODO ajouter une marge de temps à la duration
  time_appear = Math.floor(time_appear / refresh_rate) * refresh_rate;
  time_disappear = Math.floor(time_disappear / refresh_rate + 1) * refresh_rate;
  feature.properties["time_appear"] = time_appear;
  feature.properties["time_disappear"] = time_disappear;
}

/**
 * Add first and last value to trace object
 *
 * @param {string} attr attribute name
 * @param {Object} properties feature properties
 *
 * @returns updated properties
 */
function computeFirstLastProperties(data) {
  let timestamps_max = 999999;
  // timestamp
  data.timestamps.unshift(0);
  data.timestamps.push(timestamps_max);
  // values
  let first = data.values[0];
  let last = data.values[data.values.length - 1];
  data.values.unshift(first);
  data.values.push(last);

  return data;
}

/**
 * Update position of moving points
 *
 * @param {number} speed - animation speed
 * @param {object} pt
 * @param {object} position
 * @param {object} duration
 * @param {number} starttimestamp - timestamp du début de l'animation en millisecond
 * @param {number} previoustimestamp - timestamp de la frame prédécente en millisecond
 * @param {number} currenttimestamp - timestamp en cours de l'ordinateur
 * @param {array} moving_attributes
 * @param {*} map
 * @param {string} selectedMovingAttribute
 * @param {boolean} checkFollowAgent
 * @param {string} selectedAgent
 *
 * @returns {object}
 */
function newPosition2(
  speed,
  moving_features,
  position,
  duration,
  starttimestamp,
  currenttimestamp,
  map,
  selectedAttribute,
  checkFollowAgent,
  selectedAgent,
  activityShadow,
  animSpeed
) {
  let cTime = get_current_time(currenttimestamp, starttimestamp, speed);
  let icon_rules = store.state.traces.icon_rules;

  // Generate new coordinates for each object
  for (let i = 0; i < moving_features.length; i++) {
    let f = moving_features[i];
    let objId = f.num;
    // with duration
    let objDuration = duration[objId];

    let result = estimate_position(cTime, objDuration, position[objId]);
    let newcoords = result.newcoords;

    let newPoint = turf.point(newcoords);
    f.geometry.coordinates = newPoint.geometry.coordinates;

    f.properties.show_attribute = get_attribute_value(f, selectedAttribute, cTime, checkFollowAgent, selectedAgent);
    // move map if following an agent
    if (f.properties.agent_id === selectedAgent && checkFollowAgent) {
      map.jumpTo({
        center: newcoords
      });
    }
    // remove icon if before appear or after disappearing for users only
    if (f.properties.agent_type === "user") {
      if (
        activityShadow &&
        (cTime < Math.max(f.properties.time_appear_real - 3 * animSpeed, 0) ||
          cTime > Math.min(f.properties.time_disappear_real + 3 * animSpeed, 99999))
      ) {
        f.properties.icon_type = "";
      } else if (activityShadow) {
        f.properties.icon_type = "t-user";
      } else {
        f.properties.icon_type = f.properties.icon_type_save;
      }
    }

    // change the icon if any rule is applied
    if (f.properties.agent_type in icon_rules) {
      let rule_prop = icon_rules[f.properties.agent_type].prop;
      let prop_value = parseInt(get_attribute_value(f, rule_prop, cTime, false, selectedAgent));
      let ranges = icon_rules[f.properties.agent_type].rules;
      f.properties.icon_type = f.properties.icon_type_save;
      for (let j = 0; j < ranges.length; j++) {
        let range = ranges[j];
        if (range.start <= prop_value && prop_value < range.end) {
          let rule_icon = range.icon;
          if (rule_icon == "Original") {
            rule_icon = f.properties.icon_type_save;
          }
          f.properties.icon_type = colored_icon_id(rule_icon, range.color);
        }
      }
    }
  }

  return { moving_features, previous: currenttimestamp };
}

/**
 * Get attribute value for an agent
 *
 * @param {object} f feature
 * @param {string} selectedAttribute selected attribute
 * @param {integer} cTime current time
 * @param {boolean} checkFollowAgent follow or not and agent
 * @param {string} selectedAgent selected agent id
 *
 * @returns {string}
 */
function get_attribute_value(f, selectedAttribute, cTime, checkFollowAgent = false, selectedAgent = "") {
  // get showing value from attribute
  let value = "";
  // value of agent id if this attribute is selected
  if (selectedAttribute === "agent_id") {
    value = f.properties.agent_id;
  } else if (Object.keys(f.properties).includes("information")) {
    if (Object.keys(f.properties.information).includes(selectedAttribute)) {
      let indexFollowingValue = f.properties.information[selectedAttribute].timestamps.findIndex(
        get_next_element,
        cTime
      );
      let indexPreviousValue = indexFollowingValue - 1;
      value = f.properties.information[selectedAttribute].values[indexPreviousValue];
    }
  }
  // empty values if following an agent
  if (checkFollowAgent) {
    if (f.properties.agent_id !== selectedAgent) {
      value = "";
    }
  }
  return value;
}

/**
 * Estimation of object position by interpolation
 *
 * @param {*} cTime
 * @param {*} duration
 * @param {*} position
 */
function estimate_position(cTime, duration, position) {
  let objDuration = duration;
  let indexLast = objDuration.findIndex(get_next_element, cTime);

  // automatic restart at animation end
  if (indexLast === -1) {
    indexLast = 1;
    cTime = 0;
  }

  let indexFirst = indexLast - 1;

  let k = 0;
  if (objDuration[indexLast] - objDuration[indexFirst] > 0) {
    k = (cTime - objDuration[indexFirst]) / (objDuration[indexLast] - objDuration[indexFirst]); // proportion of travel between those 2 points
  }
  let iLat = position[indexFirst][1] + k * (position[indexLast][1] - position[indexFirst][1]);
  let iLng = position[indexFirst][0] + k * (position[indexLast][0] - position[indexFirst][0]);

  let newcoords = [iLng, iLat];

  let result = {
    newcoords: newcoords,
    indexFirst: indexFirst,
    indexLast: indexLast
  };

  return result;
}

/**
 * Function for updating list of moving points & stopped moving points
 *
 * @param {int} curr_time current time in seconds
 * @param {Array} mobile_features
 *
 * @returns moving points and stopped points
 */
function updateMovingPoints2(curr_time, mobile_features, position_array, use_static_layer = true) {
  let pt = JSON.parse(JSON.stringify(mobile_features));
  let stoppedpt = JSON.parse(JSON.stringify(mobile_features));

  if (use_static_layer) {
    pt = pt.filter(d => d.properties.time_appear <= curr_time && d.properties.time_disappear >= curr_time);

    stoppedpt = stoppedpt.filter(d => d.properties.time_appear > curr_time || d.properties.time_disappear < curr_time);

    // get first or last position for stopped moving points
    for (let f = 0; f < stoppedpt.length; f++) {
      let pos = 0;
      let objId = stoppedpt[f].num;
      if (stoppedpt[f].properties.time_disappear < curr_time) {
        let len = position_array[objId].length;
        pos = len - 1;
      }
      let position = position_array[objId][pos];
      stoppedpt[f].geometry.coordinates = position;
    }
  } else {
    stoppedpt = stoppedpt.filter(d => d.properties.time_appear < 0);
  }
  let movingFeatures = pt;

  let stoppedFeatures = stoppedpt;
  console.log("stopped pt " + stoppedFeatures.length + " moving pt " + movingFeatures.length);
  return { movingFeatures, stoppedFeatures };
}

/**
 * Update showing agent attribute
 *
 * @param {*} map
 * @param {*} checkFollowAgent
 * @param {*} selectedAgent
 * @param {*} movingFeatures
 * @param {*} stoppedFeatures
 * @param {*} selectedMovingAttribute
 */
function updateShowIDagents(
  map,
  checkFollowAgent,
  selectedAgent,
  movingFeatures,
  stoppedFeatures,
  selectedAttribute,
  cTime = 0
) {
  for (let i = 0; i < movingFeatures.length; i++) {
    movingFeatures[i].properties.show_attribute = get_attribute_value(
      movingFeatures[i],
      selectedAttribute,
      cTime,
      checkFollowAgent,
      selectedAgent
    );
  }
  for (let i = 0; i < stoppedFeatures.length; i++) {
    stoppedFeatures[i].properties.show_attribute = get_attribute_value(
      stoppedFeatures[i],
      selectedAttribute,
      cTime,
      checkFollowAgent,
      selectedAgent
    );
  }
  // unused now ?
  return { movingFeatures, stoppedFeatures };
}

/**
 *
 * @param {*} agentId
 * @param {*} mobile_features
 * @param {*} map
 * @param {*} currentClock
 * @param {*} startTimeStamp
 * @param {*} pos
 * @param {*} dur
 */
function zoomToSelectedAgent(agentId, mobile_features, map, currentClock, startTimeStamp, pos, dur) {
  let user_data = mobile_features.filter(d => d.properties.agent_id === agentId);
  let num = user_data[0].num;
  let currTime = (currentClock - startTimeStamp * 1000) / 1000;
  // get agent position
  let position = pos[num];
  let duration = dur[num];
  let result = estimate_position(currTime, duration, position);
  let newcoords = result.newcoords;
  map.flyTo({
    center: newcoords
  });
  return { duration, position };
}

/**
 * Make the map zoom on the trace data
 * @param {*} map
 * @param {*} trace_data
 */
function zoomOnTrace(map, trace_data) {
  let geojson = trace_data;
  let features = [];
  for (let f = 0; f < geojson.length; f++) {
    if (geojson[f].geometry.type === "Point") {
      features.push(turf.point([geojson[f].geometry.coordinates[0], geojson[f].geometry.coordinates[1]]));
    }
  }
  let fc = turf.featureCollection(features);
  zoomOnGeojson(map, fc);
}

function countAgentTypes(geojson) {
  let count = {};
  for (let obj = 0; obj < geojson["features"].length; obj++) {
    let agent_type = geojson["features"][obj]["properties"]["agent_type"];
    if (agent_type !== undefined) {
      if (!(agent_type in count)) {
        count[agent_type] = 0;
      }
      count[agent_type] += 1;
    }
  }
  let items = [];
  for (const key in count) {
    items.push({ agent_type: key, number: count[key] });
  }
  return items;
}

/**
 * Return the list of ids of the features of matching agent_type.
 *
 * @param {Array} features Geojson Feature Array
 * @param {String} agent_type Agent type(s)
 */
function agentTypeIds(features, agent_type) {
  let matching_features = null;
  if (typeof agent_type == "string") {
    matching_features = features.filter(d => d.properties.agent_type === agent_type);
  } else if (agent_type instanceof Array) {
    matching_features = features.filter(d => agent_type.includes(d.properties.agent_type));
  } else {
    throw new Error("Agent type must be either a String or an Array");
  }
  return matching_features.map(d => d.properties.agent_id).sort();
}

/**
 * Add icons to map (used by Symbol layers)
 *
 * @param {*} map
 * @param {*} icons list of 2-sized arrays containing icon id and color
 */
function add_icons_to_map(map, icons?) {
  // default to the predefined icons of Kite
  if (icons == undefined) {
    icons = Object.keys(ICONS).map(e => [e, undefined]);
  }

  let ps = [];
  // add each one of the given icons
  for (let i = 0; i < icons.length; i++) {
    ps.push(add_icon_to_map(map, icons[i][0], icons[i][1]));
  }

  // add the popup icon (.png file)
  ps.push(
    new Promise((resolve, reject) => {
      // load image
      map.loadImage(ICONS_PATH + "popup.png", (err, image) => {
        if (err) {
          reject();
        } else {
          // on image load, add it to the map
          map.addImage("popup", image, {
            stretchX: [
              [25, 55],
              [85, 115]
            ],
            stretchY: [[25, 100]],
            content: [25, 25, 115, 100],
            pixelRatio: 2
          });
          resolve(0);
        }
      });
    })
  );

  return Promise.all(ps);
}

function add_icon_to_map(map, icon_id, color) {
  return new Promise<void>(async resolve => {
    // read the content of the svg file corresponding to the icon id
    let icon_info = ICONS[icon_id];
    let svg_file = await (await fetch(ICONS_PATH + icon_info[0])).text();
    let img = new Image(icon_info[2][0], icon_info[2][1]);
    // get the icon definitive id and color
    let icon_map_id = icon_id;
    if (color != undefined) {
      icon_map_id = colored_icon_id(icon_id, color);
    }

    // test if the icon already exists
    if (store.state.traces.mapIcons.includes(icon_map_id)) {
      resolve();
      return;
    } else {
      store.dispatch("traces/addMapIcon", icon_map_id);
    }

    // after loading the icon image, add it to the map
    img.onload = () => {
      map.addImage(icon_map_id, img);
      resolve();
    };
    // replace the base color in the svg image
    if (color != undefined) {
      svg_file = svg_file.replaceAll(icon_info[1], color);
    }
    // load the icon image
    let dataUrl = "data:image/svg+xml;charset=utf8," + encodeURIComponent(svg_file);
    img.src = dataUrl;
  });
}

/**
 * Build the id of colored icons
 *
 * @param {*} icon_id
 * @param {*} color
 * @returns
 */
function colored_icon_id(icon_id, color) {
  if (color != undefined) {
    return icon_id + ":" + color;
  } else {
    return icon_id;
  }
}

/**
 * Get next index in duration
 *
 * @param {*} value
 */
function get_next_element(value) {
  return value > this;
}

// timeline and animation time

/**
 * Get current animation time
 *
 * @param {integer} currenttimestamp current time in millisecond
 * @param {integer} starttimestamp start time in millisecond
 * @param {integer} speed animation speed
 *
 * @returns {integer}
 */
function get_current_time(currenttimestamp, starttimestamp, speed) {
  let elapsedTime = currenttimestamp - starttimestamp;
  let cTime = Math.max((speed * elapsedTime) / 1000, 0);
  return cTime;
}

/**
 * Convert x position of timeline in time format hh:mm
 *
 * @param {*} pos
 * @param {*} timeLineStart
 * @param {*} hourWidth
 * @param {*} heureStart
 */
function pos2time(pos, timeLineStart, hourWidth, heureStart) {
  let currTime = getSecDayTime(pos, timeLineStart, hourWidth, heureStart);
  return sec2hourformat(currTime);
}

/**
 * Get current time of day in second from x position in timeline
 *
 * @param {*} pos
 * @param {*} timeLineStart
 * @param {*} hourWidth
 * @param {*} heureStart
 */
function getSecDayTime(pos, timeLineStart, hourWidth, heureStart) {
  let currTime = ((pos - timeLineStart) / hourWidth + heureStart) * 3600;
  return currTime;
}

export {
  ICONS,
  MAX_MOBILE_AGENTS,
  computeFirstLastProperties,
  zoomOnTrace,
  countAgentTypes,
  add_icons_to_map,
  updateMovingPoints2,
  read_trace,
  pos2time,
  newPosition2,
  updateShowIDagents,
  zoomToSelectedAgent,
  agentTypeIds
};
