<!--
Card describing a database layer
-->

<template>
  <v-card v-if="layerItem">
    <v-card-title>
      <v-chip :color="CATEGORY_COLORS.misc" dark>
        <h3>
          {{ layerItem.layer.name[language] }}
        </h3>
      </v-chip>
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-on="on" @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("basic_dialogs.close") }}</span>
      </v-tooltip>
    </v-card-title>
    <v-card-subtitle>
      {{ themes }}
    </v-card-subtitle>
    <v-card-title v-if="comments.length > 0">
      {{ "Commentaires" }}
    </v-card-title>
    <v-container>
      <li v-for="(comment, index) in comments" :key="index">{{ comment }}</li>
    </v-container>
    <v-card-title v-if="sources.length > 0">
      {{ $t("flows.database.description.sources_title") }}
    </v-card-title>
    <v-card-text v-for="(source, index) in sources" :key="2 * index">
      <kite-data-source :source="source" />
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { CATEGORY_COLORS } from "@/kite_layers";

export default Vue.component("database-layer-descriptor", {
  props: {
    // layer item to describe
    layerItem: {
      type: Object
    }
  },
  data: () => ({
    CATEGORY_COLORS
  }),

  computed: {
    ...mapState(["datasets", "language"]),
    sources() {
      return this.layerItem?.layer?.datasets || [];
    },
    themes() {
      return (this.layerItem?.theme || [])
        .map(theme => {
          return this.$t("map_layers.database_layers.themes." + theme);
        })
        .join(", ");
    },
    comments() {
      if (!this.layerItem) {
        return [];
      }
      let comments = [this.layerItem.layer.layer_comment, this.layerItem.layer.descriptor_comment];

      for (let dataset of this.layerItem.layer.datasets || []) {
        let dataset_item = this.datasets[dataset];
        comments.push(dataset_item.dataset_comment);
        comments.push(dataset_item.source_comment);
        comments.push(dataset_item.provider_comment);
      }
      comments = comments.filter(item => {
        return !!item;
      });
      return comments;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
});
</script>
