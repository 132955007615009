<template>
  <div>
    <v-card min-height="470px">
      <v-card-text style="height: 80px">
        <v-row>
          <v-col md="4">
            <h3 :style="{ color: $vuetify.theme.themes.light.primary }">
              {{ $t("map_layers.database_layers.title") }}
            </h3>
            {{ $t("map_layers.database_layers.description") }}
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('territory.dialog.search')"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col align="center" md="3">
            <v-select
              v-model="theme_filter"
              :items="themeItems"
              :label="$t('map_layers.database_layers.theme_filter')"
              :loading="async.dbLayers > 0"
              multiple
              :placeholder="$t('map_layers.database_layers.themes.empty')"
              persistent-placeholder
            >
              <!-- Select all item -->
              <template v-slot:prepend-item>
                <kite-select-all
                  :items="themeItems"
                  :selected-length="theme_filter.length"
                  :text="$t('map_layers.database_layers.themes.all')"
                  @update:selection="theme_filter = $event"
                />
              </template>
              <!-- Custom selection when too much themes are selected -->
              <template v-slot:selection="{ item, index }">
                <span v-if="theme_filter.length == 1"> {{ item.text }} </span>
                <v-chip v-if="theme_filter.length > 1 && index === 0">
                  <span>{{ $t("basic_dialogs.selected", { nb: theme_filter.length }) }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col align="center" md="3">
            <v-switch
              v-model="spatialFilter"
              :label="$t('map_layers.database_layers.refresh')"
              :disabled="async.dbLayers > 0"
              :loading="async.dbLayers > 0"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-card>
          <v-data-table
            v-bind="$attrs"
            :headers="headers"
            :items="selectedLayers"
            :loading="async.dbLayers > 0"
            class="elevation-3"
            :items-per-page="-1"
            multi-sort
          >
            <template v-slot:item.name="{ item }">
              <v-chip :color="chipColor(item)" dark>
                {{ item.layer.name[language] }}
              </v-chip>
              <v-tooltip v-if="item.layer.viewport_limited" slot="append" right>
                <template v-slot:activator="{ on }">
                  <v-icon class="pl-2" v-on="on"> explore </v-icon>
                </template>
                <span> {{ $t("map_layers.database_layers.viewport_limited_tooltip") }} </span>
              </v-tooltip>
            </template>
            <template v-slot:item.main_dataset_provider="{ item }">
              {{ datasets[item.layer.main_dataset]?.provider_name }}
            </template>
            <template v-slot:item.date="{ item }">
              {{ datasets[item.layer.main_dataset]?.date }}
            </template>
            <!-- Layer action buttons -->
            <template v-slot:item.actions="{ item }">
              <kite-table-action
                icon="info"
                @click="displayLayerInfo(item)"
                :tooltip="$t('map_layers.database_layers.layer_information')"
                :color="undefined"
              />
              <v-tooltip slot="append" bottom>
                <!-- :disabled="!item.layer.viewport_limited"-->
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    @click="item.available ? addLayer(item) : $whale.runIfHasAccess('LAYERS', addLayer, item)"
                    :color="item.available ? '#444444' : 'grey'"
                    :disabled="layerIsAdded(item)"
                  >
                    <v-icon class="justify-center"> add_circle </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t("map_layers.database_layers.add_layer") }}
                  <span v-if="item.layer.viewport_limited">{{
                    " (" + $t("map_layers.database_layers.viewport_limited_tooltip") + ")"
                  }}</span>
                </span>
              </v-tooltip>
            </template>
            <!-- Empty items text -->
            <template v-slot:no-data>
              {{ $t("map_layers.layer_table.no_data") }}
            </template>
            <!-- Translated headers -->
            <template v-slot:[header_attribute(header_obj)]="{ header }" v-for="header_obj in headers">
              {{ $t("map_layers.layer_table.headers." + header_obj.text) }}
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
    <v-bottom-sheet v-if="infoItem" v-model="infoItem" hide-overlay persistent max-width="1300px" no-click-animation>
      <v-sheet>
        <database-layer-descriptor :layer-item="infoItem" @close="infoItem = null" />
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { CATEGORY_COLORS, create_kite_layer_from_db } from "@/kite_layers";
import { create_search_list } from "@/functions-tools";
import { COLORS } from "@/global";
import DatabaseLayerDescriptor from "./database_layer_descriptor.vue";
import KiteSelectAll from "@/components/base/kite_select_all.vue";

export default Vue.component("layer-database", {
  components: {
    DatabaseLayerDescriptor,
    KiteSelectAll
  },
  props: ["dialog", "btn_class"],

  mounted() {
    this.getDatabaseLayers();
  },

  data: function () {
    return {
      theme_filter: [],
      already_loaded: false,
      headers: [
        { text: "name", value: "name", width: "40%", sortable: false },
        { text: "date", value: "date", width: "10%", align: "center", sortable: false },
        { text: "main_dataset_provider", value: "main_dataset_provider", width: "30%", sortable: false },
        { text: "actions", value: "actions", width: "10%", sortable: false, align: "center" }
      ],
      loading_layers: false,
      search: "",
      COLORS,
      infoItem: null
    };
  },

  watch: {
    databaseLayerThemes: {
      handler(value) {
        this.theme_filter = value;
      },
      immediate: true
    }
  },

  computed: {
    ...mapState(["async", "language", "datasets"]),
    ...mapState("layers", ["map", "zList"]),
    ...mapGetters("layers", ["availableDatabaseLayers", "databaseLayerThemes"]),
    spatialFilter: {
      get() {
        return this.$store.state.layers.spatialFilter;
      },
      set(value) {
        this.setSpatialFilter(value);
      }
    },
    themeItems() {
      return this.databaseLayerThemes
        .map(theme => {
          return { value: theme, text: this.$t("map_layers.database_layers.themes." + theme) };
        })
        .sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          } else if (a.text > b.text) {
            return 1;
          }
          return 0;
        });
    },
    allThemesSelected() {
      return this.theme_filter.length == this.databaseLayerThemes.length;
    },
    icon() {
      if (this.allThemesSelected) return "mdi-close-box";
      if (this.theme_filter.length > 0) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    selectedLayers() {
      let search_list = create_search_list(this.search);

      let database_layers = this.availableDatabaseLayers;

      database_layers = database_layers.filter(el => {
        return el.layer.visible;
      });

      // sort layers
      database_layers = database_layers.sort((a, b) => {
        if (this.layerIsAdded(a) && !this.layerIsAdded(b)) {
          return -1;
        } else if (this.layerIsAdded(b) && !this.layerIsAdded(a)) {
          return 1;
        } else {
          return a.layer.name[this.language].localeCompare(b.layer.name[this.language]);
        }
      });

      // filter on user search
      database_layers = database_layers.filter(
        row =>
          // search_list.some(s => row["layer"]["dataSource"].toString().toLowerCase().includes(s)) ||
          search_list.some(s => row["layer"]["name"]["fr"].toString().toLowerCase().includes(s)) ||
          search_list.some(s => row["layer"]["name"]["en"].toString().toLowerCase().includes(s))
      );

      if (this.allThemesSelected) return database_layers;
      return database_layers.filter(item => {
        return item.themes.filter(value => this.theme_filter.includes(value)).length > 0;
      });
    }
  },

  methods: {
    ...mapActions("layers", ["getDatabaseLayers", "setSpatialFilter"]),
    addLayer(item) {
      let min_zoom = item.layer.viewport_limited;
      if (min_zoom && this.map.getZoom() < min_zoom) {
        let message = this.$t("map_layers.database_layers.viewport_limit_error");
        alert({ message, type: "error" });
        return;
      }
      create_kite_layer_from_db(item.layer);
    },
    header_attribute(header_obj) {
      return "header." + header_obj.value;
    },
    selectAll() {
      if (this.allThemesSelected) {
        this.theme_filter = [];
      } else {
        this.theme_filter = this.databaseLayerThemes.slice();
      }
    },
    displayLayerInfo(item) {
      console.log(item);
      this.infoItem = item;
    },
    layerIsAdded(item) {
      return this.zList.includes(item.layer.id);
    },
    chipColor(item) {
      if (this.layerIsAdded(item)) {
        return "grey";
      } else {
        return CATEGORY_COLORS.misc;
      }
    }
  }
});
</script>
